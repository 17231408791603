@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --anji-green: #3cd0b1;
    --anji-green-bright: #7adfca;
    --anji-green-highlight: #2aaf93;

    --anji-red: #FF4B33;

    --anji-purple: #ed63f6;
    --anji-purple-bright: #f5abfa;
    --anji-purple-highlight: #e51bf2;
}

:root {
    --platform-primary: var(--anji-green);
    --platform-primary-bright: var(--anji-green-bright);
    --platform-primary-highlight: var(--anji-green-highlight);

    --platform-secondary: var(--anji-purple);
    --platform-secondary-bright: var(--anji-purple-bright);
    --platform-secondary-highlight: var(--anji-purple-highlight);

    --platform-danger: var(--anji-red);

    --platform-primary-button: linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%), var(--platform-primary);
    --platform-primary-button-hover: linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%), var(--platform-primary-highlight);

    --platform-secondary-button: linear-gradient(91.44deg, var(--platform-primary) -0.25%, var(--platform-secondary) 144.97%), linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%);
    --platform-secondary-button-hover: linear-gradient(91.44deg, var(--platform-primary-highlight) -0.25%, var(--platform-secondary-highlight) 144.97%), linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%);

    --platform-danger-button: linear-gradient(314.61deg, rgba(0, 0, 0, 0.3) -31.16%, rgba(255, 255, 255, 0.3) 136.93%), var(--platform-danger);
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #4E555E;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.anji-green {
    background: var(--platform-primary-button);
    background-blend-mode: soft-light, normal;
    position: relative;
}

.btn.anji-green:not([disabled]):hover {
    background: var(--platform-primary-button-hover);
    transform: scale(0.99);
    transition: transform 450ms ease 0s;
}

.anji-red {
    background: var(--platform-danger-button);
    background-blend-mode: soft-light, normal;
    position: relative;
}

.anji-colorful {
    background: var(--platform-secondary-button);
    background-blend-mode: soft-light, normal;
    position: relative;
}

.btn.anji-colorful:not([disabled]):hover {
    background: var(--platform-secondary-button-hover);
    background-blend-mode: soft-light, normal;
    transform: scale(0.99);
    transition: transform 450ms ease 0s;
}

.box-shadow {
    box-sizing: border-box;
    box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px #A6ABBD;
}

.dark-box-shadow {
    box-sizing: border-box;
    box-shadow: -5px -5px 10px rgba(250, 251, 255, 0.1), 5px 5px 10px #35373E;
}

.icon-btn {
    opacity: 0.6;
}

.icon-btn:hover {
    opacity: 1;
}

.text-font {
    font-family: 'Roboto', sans-serif;
}

.number-font {
    font-family: 'Roboto Mono', monospace;
}

.fade-in {
    animation: fadein 0.5s;
}

.modal-content-fade {
    animation: modaldrop 0.4s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modaldrop {
    from {
        transform: translate(0px, 50px);
    }

    to {
        transform: translate(0, 0);
    }
}
